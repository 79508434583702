import React from 'react'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'

import '@/styles/scalehack/styleCo.scss'

import { cssCorporateButton } from '@/components/scalehack/scalehackForm'

export const ScalehackContactComplete = (): JSX.Element => {
  return (
    <>
      <section css={cssSection}>
        <h1 css={cssHeading}>送信が完了しました</h1>
        <p css={cssText}>
          お問い合わせいただき、誠にありがとうございます。
          <br />
          3営業日以内にご記載の連絡先へご連絡いたします。
        </p>
        <a css={cssCorporateButton} href="/group/scalehack">
          トップページへ
        </a>
      </section>
    </>
  )
}

// Section
const cssSection = css`
  width: 100%;
  max-width: 632px;
  min-height: calc(100vh - 96px);
  padding: 112px 16px 0;
  margin: 0 auto;
  color: ${color.black2};

  ${mq.pc} {
    min-height: calc(100vh - 42px);
    padding: 236px 16px 0;
  }
`

// Heading
const cssHeading = css`
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.8px;

  ${mq.pc} {
    padding-bottom: 32px;
    font-size: 28px;
    letter-spacing: 2.8px;
  }
`

// Text
const cssText = css`
  padding-bottom: 48px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.28px;

  ${mq.pc} {
    padding-bottom: 56px;
    font-size: 18px;
    letter-spacing: 1.8px;
  }
`
